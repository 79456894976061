* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.deleteModal {
    
    padding: 1rem;
}


.deleteModal > button {
  font-size: 10px;
  /* color: #a1aaad;
  background-color: #c62033; */
  border: none;
  margin: 1rem;
  padding: 1rem 2rem;
  border-radius: .5rem;
}

.deleteModal > button:hover,
.deleteModal > button:focus,
.deleteModal > button:active,
.deleteModal > button:visited,
.active {
  
  transform: scale(1.07);
  color: black;
}

.deleteModal > p {
  font-size: .75rem;
  color: black;
  font-weight: bolder;
}
