
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.header {
  background-color: #c62033;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: sticky; /* keeps the header in the same position */
  background-color: #c62033;
  top: 0;
  z-index: 25;
  box-shadow: 0 3px 8px #a1aaad;
  padding: 10px 20px;
  
  
}



.header__top {
  display: flex;
  justify-content: space-evenly;
  
}

.header__bottom {
  display: flex;
  justify-content: center;
  background-color: #c62033;
  height: 15px;
  align-items: center;
  
}

.header__left {
  display: flex;

  justify-content: space-evenly;
}

.header__links {
  text-decoration: none;
}

.header__p {
  cursor: pointer;
  color: #a1aaad;
  font-size: 0.75rem;
  font-weight: bold;
  padding-left: 10px;
  
}

.header__center {
  display: flex;
  justify-content: center;
  width: 33%;
}

/* .header__center {
    visibility: collapse;
  } */



.header__image > img {
  width: 65px;
  height: 65px;
  z-index: 10;
}

.header__image > img:hover {
  transform: scale(1.25);
}

.header__right {
  display: flex;
  flex-direction: column;
  /* background-color: green; */
}

.header__option {
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  color: #a1aaad;
  /* background-color: blue; */
}

.header__optionLineOne {
  font-size: 0.5rem;
}

.header__optionLineTwo {
  font-size: 0.5rem;
  font-weight: 800;
  cursor: pointer;
}

.header__optionLineTwo:hover {
  transform: scale(1.07);
}
