* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.teamHeader {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.teamHeader__top > h3:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.teamHeader__top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
  background-color: #c62033;
  height: 50px;
  color: #a1aaad;
  
}

.teamHeader__bottom {
  display: flex;
  justify-content: space-evenly;
  height: 50px;
  /* background-color: yellow; */
  align-items: center;
  /* border-bottom: 1px solid black; */
  margin-bottom: 15px;
  box-shadow: 2px 3px 3px 1px #a1aaad;
}

.teamHeader__button {
  display: flex;
  align-items: center;
  /* background-color: grey; */
  height: 25px;
  background-color: #ffffff;
  outline-width: 0;
  border: none;
  cursor: pointer;
  font-size: 10px;
}

.teamHeader__button:hover,
.teamHeader__button:focus,
.teamHeader__button:active,
.teamHeader__button:visited,
.active {
  border-bottom: 2.5px solid #c62033;
  transform: scale(1.07);
  color: black;
}

@media (min-width: 1024px) {

  .teamHeader {
    
    width: auto;
  }
  .teamHeader__top {   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
