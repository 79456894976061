* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 620px;
  
 
}

.about__content {
    padding: 0 40px;
   
}

.about__content > h1 {
    text-align: center;
    padding: 20px 0;
    color: #a1aaad;
    
    
}

.about__content p {
  font-size: 12px;
  color: #a1aaad;
}

