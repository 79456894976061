* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  display: flex; 
  justify-content: center; 
  flex-direction: column;
  overflow-anchor: none;  

  /* background-color: greenyellow; */
}

.home__left {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.home__right {
 display: flex;
 flex-direction: column;
 /* width: 100%; */
}

.home__center {
  display: flex;
  flex-direction: column;
  
  /* width: 100%; */
  /* background-color: greenyellow; */
}

@media (min-width: 1300px) {
  .home {
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;    
  }

  .home__left {
   
    margin-left: 10px;
    margin-right: 10px;
    
    
  }

  .home__right {
    margin-left: 10px;
    margin-right: 10px;
   
  }

  .home__center {
    /* margin: 0 10px; */
        /* background-color: greenyellow; */
  }
}


