.batter {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.19), 0 .15rem .15rem rgba(0, 0, 0, 0.23);
  margin-bottom: .25rem;
  border-radius: .25rem;  
  padding: .65rem;
}

.batter:hover {
  transform: scale(1.05);
}

.batter__info {
  display: flex;
  width: 10rem;
  flex-direction: column;
  /* background-color: chartreuse; */
}

.batter__info > p {
  color: #a1aaad;
  font-size: .55rem;
}

.batter__position {
  color: #a1aaad
}

.batter__average {
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.batter__average > p {
  color: #a1aaad;
  font-size: .75rem;
}

.batter img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
