* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.postInput {
  display: flex;
  background-color: #c62033;

  align-items: center;
 
  justify-content: space-between;
  padding-right: 25px;
  box-shadow: 0 3px 8px #a1aaad;
  height: 50px;
  margin-bottom: 10px;
}

.postInput__left {
  display: flex;
  justify-content: flex-start;
  /* background-color: green; */
}

form[id="postInput"] {
  display: flex;
  align-items: center;
}

input[id="postInput"] {
  border: none;
  outline-width: 0;
  background-color: #ffffff;
  font-size: 10px;
  border-radius: 999px;
  padding: 3px;
  
  text-align: center;
  width: 200px;
}

input[type="text"]::placeholder {
  color: #a1aaad;
  text-align: center;
}

input[id="image_uploads"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.postInput__centerPreview {
  font-size: 0.7rem;
  color: #a1aaad;
  display: none;
}



label {
  /* background-color: green; */
  margin: 10px;
  cursor: pointer;
}

label > p {
  font-size: 10px;
}

.postInput__submit {
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .postInput {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .postInput__left {
    display: flex;
   
    /* background-color: green; */
  }

  .postInput__centerPreview {
    
  
    display: flex;
  }
}
