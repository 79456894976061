* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.postsFeed {
  overflow-y: auto;
  height: 600px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.postsFeed::-webkit-scrollbar {
  display: none;
}
