* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {
  
  overflow-x: hidden;
  /* width: 100vw; */
  /* -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border-style: solid;
  border-color: green; */
}
