* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.post {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0.75);
  margin-bottom: 10px;
}

.post__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #a1aaad;
  background-color: #c62033;
  color: #a1aaad;
  padding: 5px;
}

.post__headerInfo > p {
  font-size: 0.75rem;
  color: #a1aaad;
  margin-right: 20px;
}

.post__message {
  display: flex;
 
  min-height: 50px;
}

.post__message p {
  padding: 0.75rem;
  font-size: 0.75rem;
}

.post__image > img {
  display: flex;
  width: 100%;
  height: auto;
}

.post__icons {
  display: flex;

  border-top: 1px solid #a1aaad;
  display: flex;
  justify-content: space-evenly;
  font-size: medium;
  color: #a1aaad;
  cursor: pointer;
  padding: 5px;
}

.post__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  flex: 1;
}

.post__icon > p {
  margin-left: 0.75rem;
}

.post__icon:hover {
  background-color: #eff2f5;
  border-radius: 0.5rem;
}

.post__message > input {
  border: none;
  padding: 0.75rem 0;
  outline-width: 0;
  background-color: #ffffff;
  width: 100%;
}

.post__message > input::-webkit-input-placeholder {
  
  font-size: 1.2em;
  font-style: italic;
  background-color: #eff2f5;
  padding: .75rem;
  border-radius: .5rem;
 
  
}

.post__message > button {
  font-size: 10px;
  /* color: #a1aaad;
  background-color: #c62033; */
  border: none;
  margin: 1rem;
  padding: 1rem 2rem;
  border-radius: .5rem;
  width: 30%;
}

.post__message > button:hover,
.post__message > button:focus,
.post__message > button:active,
.post__message > button:visited,
.active {
  
  transform: scale(1.07);
  color: black;
}

@media (min-width: 1024px) {
  .post__header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
