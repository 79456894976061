.pitcherList {
  display: flex;
  flex-direction: column;
  /* background-color: green; */
  height: 100vh;
  /* overflow-y: scroll; */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
}

.pitcherList::-webkit-scrollbar {
  display: none;
}
