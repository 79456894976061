.coaches {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.19), 0 .15rem .15rem rgba(0, 0, 0, 0.23);
  margin-bottom: .25rem;
  border-radius: .25rem;  
  padding: .65rem;
}

.coaches:hover {
  transform: scale(1.05);
}

.coaches__info {
  display: flex;
  width: 10rem;
  flex-direction: column;
  
}


.coaches__position {
  color: lightgray;
  font-size: .55rem;
}

.coaches img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

