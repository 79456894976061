.pitcher {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.19),
    0 0.15rem 0.15rem rgba(0, 0, 0, 0.23);
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
  padding: 0.65rem;
}

.pitcher:hover {
  transform: scale(1.05);
}

.pitcher__era {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pitcher__era > p {
  color: #a1aaad;
  font-size: .75rem;
}

.pitcher__winSave {
  color:#a1aaad;
}

.pitcher__info {
  display: flex;
  flex-direction: column;
  width: 10rem;

}

.pitcher__info > p {
  color: #a1aaad;
  font-size: .55rem;
}

.pitcher img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
