.player {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.19),
    0 0.15rem 0.15rem rgba(0, 0, 0, 0.23);
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
  padding: 0.65rem;
}

.player:hover {
  transform: scale(1.05);
}

.player__info {
  display: flex;
  width: 10rem;
  flex-direction: column;
  /* background-color: chartreuse; */
}

.player__info > p {
  color: #a1aaad;
  font-size: 0.55rem;
}

.player__position {
  color: #a1aaad;
}

.player__average {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player__average > p {
  color: #a1aaad;
  font-size: 0.75rem;
}

.player img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}