.pitcherStory {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.pitcherStory > h2 {
  padding-bottom: 10px;

  background: -webkit-linear-gradient(#a1aaad, #c62033);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pitcherStory > h3 {
  padding-bottom: 10px;

  background: -webkit-linear-gradient(#a1aaad, #c62033);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pitcherStory__card {
  display: flex;

  justify-content: center;
  align-items: center;
  position: relative;

  border: 18px#c62033 solid;

  padding: 2px;
  background-color: #a1aaad;
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.19),
    0 0.15rem 0.15rem rgba(0, 0, 0, 0.23);
}

.pitcherStory__card:before,
.pitcherStory__card:after {
  content: "";
}

.pitcherStory__card:before {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 7;
  border-top: 30px solid #c62033;
  border-right: 30px solid transparent;
}

.pitcherStory__cardLogo {
  position: absolute;
  left: -5px;
  top: 0;
  z-index: 8;
  height: 50px;
  width: auto;
}

.pitcherStory__cardPlayer {
  z-index: 1;
  height: auto;
  width: auto;
}

figcaption {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 2px 5px;
  bottom: -5px;
  right: -5px;
  width: 66%;
  height: 40px;
  border-radius: 5px;
  background: #a1aaad;
  border: 1.5px rgb(0, 0, 0) solid;
  z-index: 5;
}
