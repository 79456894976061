.gameChanger {
  /* background-color: green; */
  display: none;
  
}

@media (min-width: 1024px) {
  .gameChanger {
    display: flex;
    /* background-color: #c62033; */
    justify-content: center;
    align-content: center;
    margin-bottom: 15px;
   padding: 10px 0;
    border-radius: 5px;
  }
}
