.teamList {
  display: flex;
  flex-direction: column;
  /* background-color: green; */
  /* height: 650px; */
  overflow-y: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
}

.teamList::-webkit-scrollbar {
  display: none;
}