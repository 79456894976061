* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.schedule {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
  overflow-y: auto;
  height: 600px;

}





.schedule > h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #a1aaad;
  background-color: #c62033;
  color: #a1aaad;
  padding: 12px;
  text-align: center;
  height: 50px;
  margin-bottom: 20px;
}

.schedule__table {
  overflow-y: auto;
  max-height: 650px;
  width: 100vw;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.schedule__table::-webkit-scrollbar {
  display: none;
}

.schedule__table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
  color: #a1aaad;
  border-bottom: 1px solid #a1aaad;
  padding-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 2px 5px;
  text-align: left;
}

tr {
  background-color: #ffffff;
}

td {
  border-bottom: 1px solid #a1aaad;
  padding: 2px 5px;
}

p {
  font-size: 0.5rem;
  color: #a1aaad;
}

h5 {
  color: #a1aaad;
}

.schedule__avatar {
  font-size: 0.6rem;
}

@media (min-width: 1024px) {
  .schedule {
    margin-top: 0;
  }
  .schedule > h3 {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .schedule__table {
    overflow-y: auto;
    max-height: 650px;
    width: auto;
  }
}
